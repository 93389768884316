import React from 'react';
import WaitlistForm from './components/WaitlistForm';

const App = () => {
  return (
    <div className="min-h-screen bg-blue-50 flex items-center justify-center px-4">
      <div className="text-center max-w-lg mx-auto">
        <h1 className="text-3xl font-bold text-blue-700 mb-4 lg:text-4xl">
          AgendaTuSalud
        </h1>
        <p className="text-lg text-gray-700 mb-8 text-sm sm:text-base lg:text-lg">
          La herramienta digital que simplifica la gestión de turnos médicos. Diseñada para personal de salud, 
          optimiza tu tiempo y mejora la experiencia de tus pacientes.
        </p>
        <WaitlistForm />
      </div>
    </div>
  );
};

export default App;
