import React from 'react';

const WaitlistForm = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto lg:max-w-lg">
      <h2 className="text-2xl font-bold text-blue-600 text-center mb-4 lg:text-3xl">
        ¡Únete a nuestra lista de espera!
      </h2>
      <p className="text-gray-700 text-center mb-6 text-sm lg:text-base">
        Sé el primero en conocer nuestra aplicación diseñada especialmente para el personal de salud. Dejanos tu correo y te mantendremos informado.
      </p>
      <form action="https://formspree.io/f/xyzyajqe" method="POST" className="space-y-4">
        <input
          type="email"
          name="email"
          placeholder="Ingresa tu email"
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
        >
          Unirme a la lista
        </button>
      </form>
    </div>
  );
};

export default WaitlistForm;
